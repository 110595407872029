import * as React from 'react';
import s from './OfferingTagLine.st.css';
import { Text } from 'wix-ui-tpa/Text';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

interface OfferingTagLineProps extends RunningEnvironmentProps {
  children?;
}

const OfferingTagLineComponent = ({
  children,
  runningEnvironment,
  ...rest
}: OfferingTagLineProps) => {
  const { isMobile } = runningEnvironment;

  return (
    <div tabIndex={-1}>
      <Text
        isMobile={isMobile}
        {...s('root', { disableFontSize: isMobile, isMobile }, rest)}
        tagName="p"
      >
        {children}
      </Text>
    </div>
  );
};

export const OfferingTagLine = withRunningEnvironmentContext(
  OfferingTagLineComponent,
);
