import * as React from 'react';
import { Experiments, mapExperiments } from '../experiments/experiments';

export interface ExperimentsContext {
  experiments: any;
}

export interface ExperimentsProps {
  experiments?: Experiments;
}

const experimentsContext = React.createContext<ExperimentsContext>(null);

export const ExperimentsContextProvider = experimentsContext.Provider;

export const ExperimentsContextConsumer = experimentsContext.Consumer;

export const withExperimentsContext = <TProps extends object>(
  WrappedComponent: React.ComponentType<TProps>,
): React.ComponentType<any> =>
  class withBiLoggerContextComponent extends React.Component<
    TProps & ExperimentsProps
  > {
    render() {
      return (
        <ExperimentsContextConsumer>
          {(context: ExperimentsContext) => {
            const experimentsProps = {
              experiments: mapExperiments(context.experiments),
            };
            return <WrappedComponent {...this.props} {...experimentsProps} />;
          }}
        </ExperimentsContextConsumer>
      );
    }
  };
