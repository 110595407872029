import * as React from 'react';
import { CategoriesViewModel } from '../../../domain/multi-offerings-view-model-factory';
import { DropDownCategories } from './DropDownCategories/DropDownCategories';
import { TabsCategories } from './TabsCategories/TabsCategories';
import { TagsCategories } from './TagsCategories/TagsCategories';
import { CategoryLayoutOptions } from '../../../../Shared/appKeys/SettingsKeys';
import s from './Categories.st.css';
import {
  withExperimentsContext,
  ExperimentsProps,
} from '../../../../Shared/context/experiments-context';

export interface CategoriesProps extends ExperimentsProps {
  categoriesViewModel: CategoriesViewModel;
  onCategorySelected: Function;
  selectedCategoryIndex: number;
}

class CategoriesComponent extends React.PureComponent<CategoriesProps> {
  getCategoryLayoutComponent() {
    const { categoriesViewModel, experiments } = this.props;
    switch (categoriesViewModel.layout) {
      case CategoryLayoutOptions.DROPDOWN:
        return experiments.isCategoryLayoutImprovementMobileEnabled
          ? DropDownCategories
          : TabsCategories;
      case CategoryLayoutOptions.TAGS:
        return experiments.isCategoryLayoutImprovementDesktopEnabled
          ? TagsCategories
          : TabsCategories;
      case CategoryLayoutOptions.TABS:
      default:
        return TabsCategories;
    }
  }

  render() {
    const CategoryLayoutComponent = this.getCategoryLayoutComponent();
    return (
      <div className={s.root}>
        <CategoryLayoutComponent {...this.props} />
      </div>
    );
  }
}

export default withExperimentsContext(CategoriesComponent);
