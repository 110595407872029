import * as React from 'react';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';
import s from './OfferingCTA.st.css';
import { ButtonStyleOptions } from '../../../../Shared/appKeys/SettingsKeys';

export interface OfferingCTAProps extends RunningEnvironmentProps {
  large?: boolean;
  secondary?: boolean;
  theme?: ButtonStyleOptions;
  onClick?: Function;
  children?: any;
}

const OfferingCTAComponent = ({
  large,
  secondary,
  theme,
  onClick,
  children,
  runningEnvironment,
  ...rest
}: OfferingCTAProps) => {
  const { isMobile } = runningEnvironment;
  return (
    <div className={s.buttonWrapper}>
      <Button
        {...s('root', { theme, isMobile, disableFontSize: isMobile }, rest)}
        size={large ? SIZE.large : SIZE.medium}
        priority={secondary ? PRIORITY.secondary : PRIORITY.primary}
        onClick={onClick}
        fullWidth={isMobile}
        aria-label="Book Now"
        role="button"
        tabIndex={0}
      >
        {children}
      </Button>
    </div>
  );
};

export const OfferingCTA = withRunningEnvironmentContext(OfferingCTAComponent);
